export const WEEKDAYS = {
  1: 'pon.',
  2: 'wt.',
  3: 'śr.',
  4: 'czw.',
  5: 'pt.',
  6: 'sob.',
  7: 'nd.'
}

export const scrollToTopSmooth = () => {
  if (window.scrollY > 0) {
    return window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
};
