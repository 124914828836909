import React, {useEffect, useState} from 'react'
import {Box, Button, Link as LinkComp, CircularProgress, Divider, Fade, Grid, Grow, LinearProgress} from "@material-ui/core";
import {useSelector} from "react-redux";
import {userSelector} from "../../features/User/UserSlice";
import {Link, useHistory} from "react-router-dom";
import {DONE_STATUS, INVOICED_STATUS} from "../../features/Projects/ProjectsSlice";
import {api} from "../../api";

const ProjectTile = ({project}) => {

  const {isPm, hasPermissionToVendorPanel} = useSelector(userSelector)
  const history = useHistory()
  const [image, setImage] = useState(null)
  const [fetchingImage, setFetchingImage] = useState(true)

  const getImage = (project) => {
    return api.get(
      `/SSingleOrders/OrderIcon/${project.id}`,
      {
        headers: {
          'X-TOKEN': localStorage.getItem('token')
        }
      }
    )
  }

  useEffect(() => {
    setFetchingImage(true)
    getImage(project).then(res => {
      setFetchingImage(false)
      if (res.data) {
        setImage(res.data.result)
      }
    })
  },[])

  return (
    <Grid item xs={12} sm={6} md={4} lg={2}>
      <Grow in>
        <Box height='100%' display='flex' justifyContent='space-between' flexDirection='column'>
          <Grid container>
            <Grid items xs={12}>
              {image && image.hasIcon
                ? <Fade in={image.hasIcon}>
                  <Box height={['50vw', '30vw', '20vw', '10vw']} style={{width: '100%'}} display='flex' alignItems='center'>
                    <a style={{width: '100%', height: '100%', cursor: 'pointer', objectFit: 'cover'}} href={isPm
                      ? (`/tasks/${project.id}`)
                      : hasPermissionToVendorPanel
                        ? (`/supplier-tasks/${project.id}`)
                        : (`/project/${project.id}`)}
                       >
                    <img
                      // onClick={() => isPm
                      //   ? history.push(`/tasks/${project.id}`)
                      //   : hasPermissionToVendorPanel
                      //     ? history.push(`/supplier-tasks/${project.id}`)
                      //     : history.push(`/project/${project.id}`)}
                      style={{width: '100%', height: '100%', cursor: 'pointer', objectFit: 'cover'}}
                      alt={image.name}
                      src={`data:image/jpeg;${image.content}`}
                    />
                    </a>

                  </Box></Fade>
                : <Box
                  height={['50vw', '30vw', '20vw','10vw']}
                  onClick={() => isPm
                    ? history.push(`/tasks/${project.id}`)
                    : hasPermissionToVendorPanel
                      ? history.push(`/supplier-tasks/${project.id}`)
                      : history.push(`/project/${project.id}`)}
                  style={{cursor: 'pointer', width: '100%', background: 'lightgrey'}}
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                >
                  {fetchingImage && <CircularProgress />}
                  {image && !image.hasIcon && <span style={{color: '#909090'}}>Brak zdjęcia</span>}
                </Box>
              }

              <Box>
                <LinkComp
                  className='hover-underline'
                  style={{cursor: 'pointer', color: 'black', fontSize: '20px', fontWeight: 'bold'}}
                  href={isPm
                    ? (`/tasks/${project.id}`)
                    : hasPermissionToVendorPanel
                      ? (`/supplier-tasks/${project.id}`)
                      : (`/project/${project.id}`)}
                >
                  {project.number}
                </LinkComp>
                <p>
                  {`Termin umowy: ${project.contractTerm || 'brak'} `}
                </p>
                <p>
                  {`${project.description}`}
                </p>
              </Box>
            </Grid>
            {/*<Grid item xs={12}>*/}
            {/*  */}
            {/*</Grid>*/}
            {/*<Grid item xs={12}>*/}
            {/*  */}
            {/*</Grid>*/}
            {/*{isPm && <Grid item xs={12}>*/}
            {/*  */}
            {/*</Grid>}*/}
          </Grid>
          <Box>
            {(isPm || hasPermissionToVendorPanel) && <Box display='flex' alignItems='flex-end'>
              <div style={{ width: '100%', position: 'relative'}}>
                <p
                  style={{
                    margin: 0,
                    position: 'absolute',
                    color: '#fff',
                    fontWeight: 900,
                    zIndex: 1,
                    height: '20px',
                    fontSize: '12px',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {project.ready}/{project.total} {project.total === 0 ? 0 : (project.ready / project.total * 100).toFixed(0)}%
                </p>
                <LinearProgress style={{height: '20px', width: '100%', borderRadius: '8px'}} variant='determinate'
                                value={project.total === 0 ? 0 : (project.ready / project.total * 100).toFixed(0)}/>
              </div>
            </Box>}
            <Divider style={{marginTop: '16px', marginBottom: '16px'}}/>
          </Box>
        </Box>
      </Grow>
    </Grid>
  )
}

export default ProjectTile
