import {Box, Button, useMediaQuery} from "@material-ui/core";
import PlatformaTechniczna from "../../assets/images/ProjectTracking.svg";
import Visualcom from "../../assets/images/Visualcom.svg";
import {ExitToApp} from "@material-ui/icons";
import React, { Fragment } from "react";
import {useHistory} from "react-router-dom";
import {userSelector} from "../../features/User/UserSlice";
import {useSelector} from "react-redux";


const Header = () => {
  const history = useHistory();

  const { name } = useSelector(userSelector)


  const onLogOut = () => {
    localStorage.clear()

    history.push('/login');
  };

  const isMediumUpScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));

  return (
    <Fragment>
      <Box p={[1, 2, 3]} display='flex' justifyContent='space-between' alignItems='center'>
        <Box
          // maxHeight={['20px', '25px', '50px']}
          onClick={async () => {
            await history.push('/')
            window.location.reload()
          }}
        >
          <img src={PlatformaTechniczna} alt='logo' style={{cursor: 'pointer', height: '100%', width: '100%', maxWidth: '150px'}} />
        </Box>
        {isMediumUpScreen && <Box maxHeight={['20px', '25px', '50px']}>
          <img src={Visualcom} alt='logo' style={{maxHeight: 'inherit', marginRight: '20px'}}/>
        </Box>}
        <Box display='flex'>
          <p style={{marginRight: '20px', fontSize: '12px'}}>Zalogowano jako:<br/> <b>{name}</b></p>
          <Button
            style={{margin: 0}}
            onClick={onLogOut}
            startIcon={<ExitToApp/>}
          >
            Wyloguj
          </Button>
        </Box>
      </Box>
      <div style={{width: '100%', height: '1px', boxShadow: '0px -1px 2px rgba(0, 0, 0, 0.25)'}} />
    </Fragment>
  )
}

export default Header
