import {Box, Button, CircularProgress, Grid, Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {InsertDriveFile, PictureAsPdf, CloudDownload} from "@material-ui/icons";
import React, {useEffect, useState} from "react";
import {api} from "../../api";
import {useSelector} from "react-redux";
import {userSelector} from "../User/UserSlice";
import {Grow} from "@material-ui/core";

const PLMTaskDocument = ({doc, setImagePreview, setShowImagePreview, setConfirmationDialog, setCurrentTask, setCurrentTaskIndex, taskIndex, task, setFileToDelete, withPreload}) => {
  const { isPm, hasPermissionToVendorPanel } = useSelector(userSelector)

  const [myDocument, setDocument] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isLoadedForPm, setIsLoadedForPm] = useState(false)

  const getFile = (file) => {
    return api.get(
      `/Documents/FileBase64/${file.id}`,
      {
        headers: {
          'X-TOKEN': localStorage.getItem('token')
        }
      }
    )
  }

  useEffect(async () => {
    if (withPreload) {
      if (!myDocument) {
        const file = await getFile(doc)
          .then(res => ({...doc, url: res?.data?.result.content, docType: res?.data?.result.type}))
        setDocument(file)
        setIsLoaded(true)
        setIsLoadedForPm(true)
      }
    }
  }, [withPreload])

  if (isPm && !isLoadedForPm) {
    return <Grid item xs={2}>
      <Grow in={true}>
        <div>
          <Tooltip title={'Kliknij aby wyświetlić miniaturkę'}>
            <Box>
              <Box display='flex' justifyContent='center'>
                <IconButton color='primary' onClick={async () => {
                  const file = await getFile(doc)
                    .then(res => ({...doc, url: res?.data?.result.content, docType: res?.data?.result.type}))
                  setDocument(file)
                  setIsLoaded(true)
                  setIsLoadedForPm(true)
                }}>
                  <CloudDownload style={{fontSize: 80}} />
                </IconButton>
              </Box>
              <p style={{textAlign: 'center', marginTop: '5px', wordBreak: 'break-all', cursor: 'pointer'}} onClick={async () => {
                const file = await getFile(doc)
                  .then(res => ({...doc, url: res?.data?.result.content, docType: res?.data?.result.type}))
                setDocument(file)
                setIsLoaded(true)
                setIsLoadedForPm(true)
              }}>
                {doc.fileName}
              </p>
          </Box>
          </Tooltip>
        </div>
      </Grow>
    </Grid>
  }

  if (!isLoaded) {
    return (
      <Grid item xs={2}>
        <Box height='200px' display='flex' justifyContent='center' alignItems='center'>
          <CircularProgress />
        </Box>
      </Grid>
    )
  }

  if (myDocument.docType === 'image/png' || myDocument.docType === 'image/jpg' || myDocument.docType === 'image/jpeg') {
    return (
      <Grid item xs={2}>
        <Grow in={isLoaded}>
          <div>
            <Box height='100%' display='flex' justifyContent='center' flexDirection='column'>
            <a
              onClick={e => {
                e.preventDefault()
              }}
              href={`${myDocument.url ? myDocument.url : URL.createObjectURL(myDocument.data)}`}
              download={myDocument?.fileName || myDocument?.title}>
            <img
              onClick={() => {
                setShowImagePreview(true)
                setImagePreview(myDocument)
                setCurrentTask(task)
                setCurrentTaskIndex(taskIndex)
              }}
              style={{maxWidth: '100%', maxHeight: '600px', borderRadius: '8px', cursor: 'pointer', objectFit: 'cover'}}
              alt={myDocument.title}
              src={`${myDocument.url ? myDocument.url : URL.createObjectURL(myDocument.data)}`}
            />
            </a>
            {(isPm || hasPermissionToVendorPanel) && <p style={{ margin: '5px 0'}}><b>Dodano: </b><br/>{doc?.created}</p>}
            {setFileToDelete && (isPm || hasPermissionToVendorPanel) && <Box display='flex' justifyContent='center'>
              <Button
                onClick={() => {
                  setFileToDelete(myDocument)
                  setConfirmationDialog(true)
                }}
              >
                usuń
              </Button>
            </Box>}
          </Box>
          </div>
        </Grow>
      </Grid>
    )
  } else if (myDocument.docType === 'application/pdf') {
    return (
      <Grid item xs={2}>
        <Grow in={isLoaded}>
          <div>
            <Box>
              <Box display='flex' justifyContent='center'>
                <IconButton color='primary' onClick={() => {
                  var a = document.createElement("a"); //Create <a>
                  a.href = myDocument.url; //Image Base64 Goes here
                  a.download = myDocument.fileName; //File name Here
                  a.click(); //Downloaded file
                }}>
                  <PictureAsPdf style={{fontSize: 80}} />
                </IconButton>
              </Box>
              <p style={{textAlign: 'center', marginTop: '5px', wordBreak: 'break-all', cursor: 'pointer'}} onClick={() => {
                var a = document.createElement("a"); //Create <a>
                a.href = myDocument.url; //Image Base64 Goes here
                a.download = myDocument.fileName; //File name Here
                a.click(); //Downloaded file
              }}>
                {myDocument.fileName}
              </p>
            </Box>
            {setFileToDelete && <Box display='flex' justifyContent='center'>
              <Button
                onClick={() => {
                  setFileToDelete(myDocument)
                  setConfirmationDialog(true)
                }}
              >
                usuń
              </Button>
            </Box>}
          </div>
        </Grow>
      </Grid>
    )
  } else {
    return (
      <Grid item xs={2}>
        <Grow in={isLoaded}>
          <div>
            <Box>
              <Box display='flex' justifyContent='center'>
                <IconButton color='primary' onClick={() => {
                  var a = document.createElement("a"); //Create <a>
                  a.href = myDocument.url; //Image Base64 Goes here
                  a.download = myDocument.fileName; //File name Here
                  a.click(); //Downloaded file
                }}>
                  <InsertDriveFile style={{fontSize: 80}} />
                </IconButton>
              </Box>
              <p style={{textAlign: 'center', marginTop: '5px', wordBreak: 'break-all', cursor: 'pointer'}} onClick={() => {
                var a = document.createElement("a"); //Create <a>
                a.href = myDocument.url; //Image Base64 Goes here
                a.download = myDocument.fileName; //File name Here
                a.click(); //Downloaded file
              }}>
                {myDocument.fileName}
              </p>
            </Box>
            {setFileToDelete && <Box display='flex' justifyContent='center'>
              <Button
                onClick={() => {
                  setFileToDelete(myDocument)
                  setConfirmationDialog(true)
                }}
              >
                usuń
              </Button>
            </Box>}
          </div>
        </Grow>
      </Grid>
    )
  }
}

export default PLMTaskDocument
