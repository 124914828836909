import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {api} from "../../api";

export const loginUser = createAsyncThunk(
  'users/login',
  async ({ user, password }, thunkAPI) => {
    try {
      const response = await api.request(
        {
          url: '/Users/Login',
          method: 'POST',
          headers: {
          },
          data: {
            userName: user,
            password: password
          }
        }
      );
      let data = response.data.result
      if (response.status === 200) {
        localStorage.setItem('token', data.token);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data.error);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getUser = createAsyncThunk(
  'users/me',
  async (_, thunkAPI) => {
    try {
      const response = await api.request(
        {
          url: '/Users/WTF',
          method: 'GET',
          headers: {
            'X-TOKEN': localStorage.getItem('token'),
          },
        }
      );
      let data = response.data.result
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data.error);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    name: '',
    isPm: false,
    hasPermissionToVendorPanel: false,
    locations: [],
    fetchedUser: false,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    activeTab: '2',
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.fetchedUser = false;
      state.isPm = false;
      state.hasPermissionToVendorPanel = false;

      return state;
    },
    setActiveTab: (state, {payload}) => {
      state.activeTab = payload
    }
  },
  extraReducers: {
    [loginUser.fulfilled]: (state, { payload }) => {
      state.name = payload.name;
      state.isPm = payload.isPm;
      state.hasPermissionToVendorPanel = payload.hasPermissionToVendorPanel
      state.isFetching = false;
      state.isSuccess = !(!payload.isPm && !payload.hasPermissionToVendorPanel && !payload.isAccount);
      state.fetchedUser = true;

      if (!payload.isPm && !payload.hasPermissionToVendorPanel && !payload.isAccount) {
        state.isError = true
        state.errorMessage = 'Niepoprawne dane logowania'
      }

      if (payload.locations) {
        state.locations = payload.locations
      }

      return state;
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;

      if (payload.error.status === 401 && !payload.error.message) {
        state.errorMessage = 'Niepoprawne dane logowania'
      } else {
        state.errorMessage = payload.error.message;
      }

    },
    [loginUser.pending]: (state) => {
      state.isFetching = true;
    },
    [getUser.fulfilled]: (state, { payload }) => {
      state.name = payload.name;
      state.isPm = payload.isPm;
      state.hasPermissionToVendorPanel = payload.hasPermissionToVendorPanel
      state.isFetching = false;
      state.fetchedUser = true;
      if (payload.locations) {
        state.locations = payload.locations
      }
      return state;
    },
    [getUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.error.message;
    },
    [getUser.pending]: (state) => {
      state.isFetching = true;
    },
  },
});

export const { clearState, setActiveTab } = userSlice.actions;

export const userSelector = (state) => state.user;
