import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {getDoneOrInvoicedProjects, getStartedProjects, setStartedPage, setDoneOrInvoicedPage, projectsSelector} from "./ProjectsSlice";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Button,
  Select,
  Fade,
  MenuItem,
  InputLabel,
  FormControl, makeStyles, Icon
} from '@material-ui/core'
import ProjectTile from "../../common/Components/ProjectTile";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import {ArrowForward, Close, Search} from "@material-ui/icons";
import {userSelector} from "../User/UserSlice";
import Pagination from '@material-ui/lab/Pagination';
import {Link, useHistory} from "react-router-dom";
import {Helmet} from "react-helmet";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Projects = () => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const history = useHistory()
  const {started, doneOrInvoiced, contractors, isFetching} = useSelector(projectsSelector)
  const {isPm, hasPermissionToVendorPanel, fetchedUser} = useSelector(userSelector)
  const [sortedContractors, setSortedContractors] = useState([])

  const [projectSearchValue, setProjectSearchValue] = useState(localStorage.getItem('projectSearchValue') || '')
  const [contractorSearchValue, setContractorSearchValue] = useState(localStorage.getItem('contractorSearchValue') || null)

  const [startedPerPage, setStartedPerPage] = useState(12)
  const [doneOrInvoicedPerPage, setDoneOrInvoicedPerPage] = useState(12)

  useEffect(() => {
    dispatch(getStartedProjects({ number: projectSearchValue, contractor: contractorSearchValue, page: started.page, perPage: startedPerPage }))
  }, [started.page, history.location, startedPerPage])

  useEffect(() => {
    if (!hasPermissionToVendorPanel) {
      dispatch(getDoneOrInvoicedProjects({ number: projectSearchValue, contractor: contractorSearchValue, page: doneOrInvoiced.page, perPage: doneOrInvoicedPerPage }))
    }
  }, [doneOrInvoiced.page, history.location, doneOrInvoicedPerPage])

  return (
    <Fade in={fetchedUser}>
      <Box textAlign='left' p={2}>
        <Helmet defer={false}>
          <title>VPT - Lista projektów</title>
        </Helmet>
        <Box mb={5}>
          <Grid container alignItems='center'>
            {(isPm) && <Grid item xs={6} lg={6}>
              <Box mr={4} display='flex' alignItems='center'>
                <h5 style={{marginRight: '15px', width: 'fit-content', whiteSpace: 'no-wrap'}}>
                  Wyszukaj projekt po kontrahencie:
                </h5>
                <Select
                  value={contractorSearchValue}
                  onChange={e => {
                    localStorage.setItem('contractorSearchValue', e.target.value)
                    setContractorSearchValue(e.target.value)
                  }}
                  native
                  style={{height: '40px', width: '100%'}}
                  variant='outlined'
                >
                  <option value={0}>
                    Wszyscy
                  </option>
                  {
                    [...contractors].sort((a, b) => a.name.localeCompare(b.name)).map(contractor => {
                      return (
                        <option value={parseInt(contractor.id)}>
                          {contractor.name}
                        </option>
                      )
                    })
                  }
                </Select>
                <IconButton
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    localStorage.setItem('contractorSearchValue', 0)
                    setContractorSearchValue(0)
                    dispatch(setStartedPage(1))
                    dispatch(setDoneOrInvoicedPage(1))
                    dispatch(getStartedProjects({ number: projectSearchValue, contractor: 0, page: started.page }))
                    dispatch(getDoneOrInvoicedProjects({ number: projectSearchValue, contractor: 0, page: doneOrInvoiced.page }))

                  }}
                  style={{marginLeft: '10px'}}
                >
                  <Close/>
                </IconButton>
                <IconButton
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    dispatch(getStartedProjects({ number: projectSearchValue, contractor: contractorSearchValue, page: started.page }))
                    dispatch(getDoneOrInvoicedProjects({ number: projectSearchValue, contractor: contractorSearchValue, page: doneOrInvoiced.page }))
                    dispatch(setStartedPage(1))
                    dispatch(setDoneOrInvoicedPage(1))
                  }}
                  style={{marginLeft: '10px'}}
                >
                  <ArrowForward/>
                </IconButton>
              </Box>
            </Grid>}
            <Grid item xs={12} lg={6}>
              <Box mr={4} display='flex' alignItems='center'>
                <h5 style={{marginRight: '15px', width: 'fit-content', whiteSpace: 'no-wrap'}}>
                  Wyszukaj projekt po nazwie lub numerze:
                </h5>
                <OutlinedInput
                  style={{height: '40px', width: '100%'}}
                  type='text'
                  value={projectSearchValue}
                  onChange={(e) => setProjectSearchValue(e.target.value)}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      localStorage.setItem('projectSearchValue', e.target.value)
                      dispatch(getStartedProjects({ number: e.target.value, contractor: contractorSearchValue, page: started.page }))
                      dispatch(getDoneOrInvoicedProjects({ number: e.target.value, contractor: contractorSearchValue, page: doneOrInvoiced.page }))

                      dispatch(setStartedPage(1))
                      dispatch(setDoneOrInvoicedPage(1))
                    }
                  }}
                />
                <IconButton
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    localStorage.setItem('projectSearchValue', projectSearchValue)
                    dispatch(getStartedProjects({ number: projectSearchValue, contractor: contractorSearchValue, page: started.page }))
                    dispatch(getDoneOrInvoicedProjects({ number: projectSearchValue, contractor: contractorSearchValue, page: doneOrInvoiced.page }))
                    dispatch(setStartedPage(1))
                    dispatch(setDoneOrInvoicedPage(1))
                  }}
                  style={{marginLeft: '10px'}}
                >
                  <Search />
                </IconButton>
              </Box>
            </Grid>
            {(!isPm && !hasPermissionToVendorPanel) && <Grid item xs={12} lg={6}>
              <Box display='flex' alignItems='center'>
                <h5 style={{marginRight: '15px', width: 'fit-content', whiteSpace: 'no-wrap'}}>
                  lub sprawdź
                </h5>
                <Link style={{textDecoration: 'none'}} to={'/locations'}>
                  <Button
                    color='primary'
                    variant='contained'
                  >
                    historia prac na lokalizacji
                  </Button>
                </Link>
              </Box>
            </Grid>}
          </Grid>
          <Divider />
        </Box>
        <Box>
          <h2>
            {hasPermissionToVendorPanel ? 'Zlecenia w trakcie' : 'Projekty w trakcie:'}
          </h2>
          <Box my={2}>
            <Divider />
          </Box>
          <Grid container spacing={2}>
            {
              started.recordsList.length === 0
                ? <p style={{margin: '20px 0 20px 10px'}}>Brak wyników</p>
                : started.recordsList.map(
                  item => {
                    return <ProjectTile key={item.id} project={item} />
                  }
                )
            }
          </Grid>
          <Box mt={2} display='flex' justifyContent='flex-end' alignItems='center'>
            <FormControl className={classes.formControl}>
              <InputLabel>Na stronę</InputLabel>
              <Select
                value={startedPerPage}
                onChange={(ev) => setStartedPerPage(ev.target.value)}
              >
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={12}>12</MenuItem>
                <MenuItem value={24}>24</MenuItem>
                <MenuItem value={36}>36</MenuItem>
                <MenuItem value={48}>48</MenuItem>
                <MenuItem value={60}>60</MenuItem>
              </Select>
            </FormControl>
            <Pagination shape='rounded' page={started.page} onChange={(event, page) => dispatch(setStartedPage(page))} count={started.lastPage} />
          </Box>
        </Box>
        {!hasPermissionToVendorPanel && <Box mt={10}>
          <h2>
            Projekty zrealizowane:
          </h2>
          <Box my={2}>
            <Divider />
          </Box>
          <Grid container spacing={2}>
            {
              doneOrInvoiced.recordsList.length === 0
                ? <p style={{margin: '20px 0 20px 10px'}}>Brak wyników</p>
                : doneOrInvoiced.recordsList.map(
                  item => {
                    return <ProjectTile key={item.id} project={item}/>
                  }
                )
            }
          </Grid>
          <Box mt={2} display='flex' justifyContent='flex-end' alignItems='center'>
            <FormControl className={classes.formControl}>
              <InputLabel>Na stronę</InputLabel>
              <Select
                value={doneOrInvoicedPerPage}
                onChange={(ev) => setDoneOrInvoicedPerPage(ev.target.value)}
              >
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={12}>12</MenuItem>
                <MenuItem value={24}>24</MenuItem>
                <MenuItem value={36}>36</MenuItem>
                <MenuItem value={48}>48</MenuItem>
                <MenuItem value={60}>60</MenuItem>
              </Select>
            </FormControl>

            <Pagination shape='rounded' page={doneOrInvoiced.page}
                        onChange={(event, page) => dispatch(setDoneOrInvoicedPage(page))}
                        count={doneOrInvoiced.lastPage}/>
          </Box>
        </Box>}
      </Box>
    </Fade>
  )
}

export default Projects
