import {Box, Button, CircularProgress, Fade, Grid, Grow} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {InsertDriveFile, PictureAsPdf} from "@material-ui/icons";
import React, {useEffect, useState} from "react";
import {api} from "../../api";
import {useSelector} from "react-redux";
import {userSelector} from "../../features/User/UserSlice";

const TaskDocument = ({doc, setImagePreview, setShowImagePreview, setConfirmationDialog, setFileToDelete}) => {

  const [myDocument, setDocument] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const { isPm, hasPermissionToVendorPanel } = useSelector(userSelector)

  const getFile = (file) => {
    return api.get(
      `/Documents/FileBase64/${file.id}`,
      {
        headers: {
          'X-TOKEN': localStorage.getItem('token')
        }
      }
    )
  }

  useEffect(async () => {
    const file = await getFile(doc)
      .then(res => ({...doc, url: res?.data?.result.content, docType: res?.data?.result.type}))
    setDocument(file)
    setIsLoaded(true)
  }, [])

  if (!isLoaded) {
    return (
      <Grid item xs={4}>
        <Box height='200px' display='flex' justifyContent='center' alignItems='center'>
          <CircularProgress />
        </Box>
      </Grid>
    )
  }

  if (myDocument.docType === 'image/png' || myDocument.docType === 'image/jpg' || myDocument.docType === 'image/jpeg') {
    return (
      <Grid item xs={4}>
        <Grow in={isLoaded}>
          <Box textAlign='center'>
            <a
              onClick={e => e.preventDefault()}
              href={`${myDocument.url ? myDocument.url : URL.createObjectURL(myDocument.data)}`}
              download={myDocument?.fileName || myDocument?.title}>
              <img
                onClick={() => {
                  setShowImagePreview(true)
                  setImagePreview(myDocument)
                }}
                style={{maxWidth: '100%', borderRadius: '8px', cursor: 'pointer'}}
                alt={myDocument.title}
                src={`${myDocument.url ? myDocument.url : URL.createObjectURL(myDocument.data)}`}
              />
            </a>
            <p style={{textAlign: 'center', marginTop: '5px', wordBreak: 'break-all', cursor: 'pointer'}} onClick={() => {
              var a = document.createElement("a"); //Create <a>
              a.href = myDocument.url; //Image Base64 Goes here
              a.download = myDocument.fileName; //File name Here
              a.click(); //Downloaded file
            }}>
              {(isPm || hasPermissionToVendorPanel) && <p style={{ textAlign: 'left', margin: '5px 0'}}><b>Dodano: </b><br/>{doc?.created}</p>}
            </p>
            {(isPm || hasPermissionToVendorPanel) && setFileToDelete && <Box display='flex' justifyContent='center'>
              <Button
                onClick={() => {
                  setFileToDelete(myDocument)
                  setConfirmationDialog(true)
                }}
              >
                usuń
              </Button>
            </Box>}
          </Box>
        </Grow>
      </Grid>
    )
  } else if (myDocument.docType === 'application/pdf') {
    return (
      <Grid item xs={4}>
        <Grow in={isLoaded}>
          <Box>
            <Box>
              <Box display='flex' justifyContent='center'>
                <IconButton color='primary' onClick={() => {
                  var a = document.createElement("a"); //Create <a>
                  a.href = myDocument.url; //Image Base64 Goes here
                  a.download = myDocument.fileName; //File name Here
                  a.click(); //Downloaded file
                }}>
                  <PictureAsPdf style={{fontSize: 80}} />
                </IconButton>
              </Box>
              <p style={{textAlign: 'center', marginTop: '5px', wordBreak: 'break-all', cursor: 'pointer'}} onClick={() => {
                var a = document.createElement("a"); //Create <a>
                a.href = myDocument.url; //Image Base64 Goes here
                a.download = myDocument.fileName; //File name Here
                a.click(); //Downloaded file
              }}>
                {myDocument.fileName}
              </p>
            </Box>
            {(isPm || hasPermissionToVendorPanel) && setFileToDelete && <Box display='flex' justifyContent='center'>
              <Button
                onClick={() => {
                  setFileToDelete(myDocument)
                  setConfirmationDialog(true)
                }}
              >
                usuń
              </Button>
            </Box>}
          </Box>
        </Grow>
      </Grid>
    )
  } else {
    return (
      <Grid item xs={4}>
        <Grow in={isLoaded}>
          <Box>
            <Box>
              <Box display='flex' justifyContent='center'>
                <IconButton color='primary' onClick={() => {
                  var a = document.createElement("a"); //Create <a>
                  a.href = myDocument.url; //Image Base64 Goes here
                  a.download = myDocument.fileName; //File name Here
                  a.click(); //Downloaded file
                }}>
                  <InsertDriveFile style={{fontSize: 80}} />
                </IconButton>
              </Box>
              <p style={{textAlign: 'center', marginTop: '5px', wordBreak: 'break-all', cursor: 'pointer'}} onClick={() => {
                var a = document.createElement("a"); //Create <a>
                a.href = myDocument.url; //Image Base64 Goes here
                a.download = myDocument.fileName; //File name Here
                a.click(); //Downloaded file
              }}>
                {myDocument.fileName}
              </p>
            </Box>
            {(isPm || hasPermissionToVendorPanel) && setFileToDelete && <Box display='flex' justifyContent='center'>
              <Button
                onClick={() => {
                  setFileToDelete(myDocument)
                  setConfirmationDialog(true)
                }}
              >
                usuń
              </Button>
            </Box>}
          </Box>
        </Grow>
      </Grid>
    )
  }
}

export default TaskDocument
