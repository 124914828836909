import React, {useEffect, useState} from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid
} from "@material-ui/core";
import PLMTaskDocument from "./PLMTaskDocument";
import {Check, Close, NoteAdd} from "@material-ui/icons";
import {toast} from "react-toastify";
import {api} from "../../api";
import IconButton from "@material-ui/core/IconButton";
import Dropzone from "react-dropzone";
import {useSelector} from "react-redux";
import {userSelector} from "../User/UserSlice";
import {Link as RouterLink} from "react-router-dom";

const PLMTask = ({updateTasks, task, projectId, setCurrentTask, setCurrentTaskIndex, setImagePreview, setShowImagePreview, taskIndex, withPreload}) => {
  const { isPm, hasPermissionToVendorPanel } = useSelector(userSelector)

  const [ postAssemblyDocs, setPostAssemblyDocs ] = useState([])
  const [ fileToDelete, setFileToDelete ] = useState(null)
  const [ confirmationDialog, setConfirmationDialog ] = useState(false)
  const [ capturing, setCapturing ] = useState(false)

  useEffect(() => {
    if (task?.documents.length > 0) {
      const postAssembly = task.documents.filter(doc => doc.type === 'Dokumentacja pomontażowa')

      setPostAssemblyDocs(postAssembly)
    }
  }, [task])

  const onDrop = async (acceptedFiles, action, prevFiles, docType) => {
    setCapturing(true)
    const newFiles = [...acceptedFiles]
    let responses = []

    for (let i = 0; i < newFiles.length; i++) {
      let formData = new FormData();

      formData.append("filename", newFiles[i])
      formData.append("projektdokument", projectId)
      formData.append("sourceRecord", task.id)
      formData.append("notes_title", newFiles[i].name)
      formData.append("typdokumentu", docType)
      formData.append('filelocationtype', 'I')
      formData.append('sourceModule', 'AssemblyTasks')
      formData.append('relationOperation', true)
      formData.append('relationId', 651)

      const uploaded = await api.post("/Documents/Record", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Accept': 'multipart/form-data',
          'X-TOKEN': localStorage.getItem('token')
        },
      })

      const transformed = {
        data: newFiles[i],
        fileName: newFiles[i].name,
        type: newFiles[i].type,
        title: newFiles[i].name,
        id: uploaded?.data?.result?.id
      }

      responses.push(transformed)
    }
    updateTasks()
    action([...prevFiles, ...responses])
    setCapturing(false)
  }

  const deleteFile = (id) => {
    return api.request({
      url: `/Documents/Record/${id}`,
      method: 'DELETE',
      headers: {
        'X-TOKEN': localStorage.getItem('token')
      }
    })
  }

  return (
    <Box>
      <h4>{`${task.location?.locationSymbol}${task.location.locationSymbol ? ' ' : ''}${task.location?.name}`} {task.location.city}, {task.location.street} {task.location.buildingNumber}
        <RouterLink style={{textDecoration: 'none', marginLeft:
        '16px'}} to={isPm
          ? (`/tasks/${projectId}/${task.id}`)
          : hasPermissionToVendorPanel
            ? (`/supplier-tasks/${projectId}/${task.id}`)
            : (`/project/${projectId}/${task.id}`)}>
          <Button
            variant='contained'
            color='primary'
            style={{height: '24px'}}
          >
            Szczegóły
          </Button>
        </RouterLink>

      </h4>

      {isPm && <p style={{mt: 0}}><b>Technik: </b>{task.supplier || '-'}</p>}
      {isPm && <p style={{mt: 0}}><b>Data ostatniej modyfikacji: </b>{task.modificationDate || '-'}</p>}
      <p style={{mt: 0, color: task?.note ? 'red' : 'inherit'}}><b>Notatka: </b>{task.note || '-'}</p>
      <Grid container spacing={2}>
        {postAssemblyDocs.map(doc => {
          return (
            <PLMTaskDocument
              key={doc.id}
              doc={doc}
              setImagePreview={setImagePreview}
              taskIndex={taskIndex}
              setCurrentTask={setCurrentTask}
              setCurrentTaskIndex={setCurrentTaskIndex}
              task={task}
              setShowImagePreview={setShowImagePreview}
              setConfirmationDialog={setConfirmationDialog}
              setFileToDelete={setFileToDelete}
              withPreload={withPreload}
            />
          )
        })}
        {(isPm || hasPermissionToVendorPanel) && <Grid item xs={2}>
          <Box height='100%' display='flex' justifyContent='center' flexDirection='column'>
            <Dropzone
              onDrop={acceptedFiles => onDrop(acceptedFiles, setPostAssemblyDocs, postAssemblyDocs, 'Dokumentacja pomontażowa')}>
              {({getRootProps, getInputProps}) => (
                <div style={{border: '1px dashed grey', borderRadius: '8px'}} {...getRootProps()}>
                  <Box display='flex' justifyContent='center'>
                    <IconButton color='primary' component='label'>
                      {/*<input*/}
                      {/*  type='file'*/}
                      {/*  accept='image/jpg, image/png, image/jpeg, application/pdf, application/docx, application/xlsx'*/}
                      {/*  hidden*/}
                      {/*  multiple*/}
                      {/*  onChange={ev => handleCapture(ev, setPreAssemblyDocs, preAssemblyDocs, 'Dokumentacja przedmontażowa')}*/}
                      {/*/>*/}
                      <NoteAdd style={{fontSize: 60}}/>
                    </IconButton>
                  </Box>
                  <Box display='flex' justifyContent='center'>
                    <Button
                      variant='contained'
                      color='primary'
                      component='label'
                      style={{height: '24px'}}
                    >
                      Dodaj pliki
                    </Button>
                  </Box>
                  <input {...getInputProps()} />
                  <p style={{fontWeight: 600, textAlign: 'center'}}>lub upuść tutaj</p>
                </div>
              )}
            </Dropzone>
          </Box>
        </Grid>}

      </Grid>

      <Dialog maxWidth='xl' open={capturing}>
        <DialogContent>
          <h3>Trwa dodawanie plików...</h3>
          <Box display='flex' justifyContent='center' m={4}>
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog maxWidth='lg' open={confirmationDialog} onClose={() => setConfirmationDialog(false)}>
        <DialogContent>
          Czy na pewno chcesz usunąć dokument <b>{fileToDelete?.fileName}</b>?
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => {
            setConfirmationDialog(false)
          }} color="primary" startIcon={<Close />}>
            Nie
          </Button>
          <Button variant='contained' onClick={() => {
            setConfirmationDialog(false)
            deleteFile(fileToDelete?.id).then(() => {
              const filteredPost = postAssemblyDocs.filter(doc => doc.id !== fileToDelete?.id)
              setPostAssemblyDocs(filteredPost)
              updateTasks()
              toast.warning('Dokument został usunięty')
            })
          }} color="primary" autoFocus startIcon={<Check />}>
            Tak
          </Button>
        </DialogActions>
      </Dialog>
      <Box mt={2}>
        <Divider />
      </Box>
    </Box>

  )
}

export default PLMTask
