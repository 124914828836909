import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (localStorage.getItem('token')) {
        return <Component {...props} />
      } else {
        window.requestedUrl = window.location.pathname + rest.location.search
        return <Redirect
          to={{ pathname: '/login', state: { from: props.location } }}
        />
      }
    }}
  />
);
