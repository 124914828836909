import React, {useEffect, useState} from 'react'
import {Box, Button, CircularProgress, Dialog, DialogContent, Divider, FormControlLabel, Switch, Grid} from "@material-ui/core";
import PLMTask from "./PLMTask";
import {api} from "../../api";
import {useSelector} from "react-redux";
import {userSelector} from "../User/UserSlice";
import IconButton from "@material-ui/core/IconButton";
import {ArrowBackIos, ArrowForwardIos, Close, InsertDriveFile, PictureAsPdf} from "@material-ui/icons";
import {useKeyPress} from "../../helpers/hooks";
import InfiniteScroll from "react-infinite-scroll-component";

const PLMSection = ({
                      updateTasks,
                      doneTasks,
                      projectId,
                      currentPage,
                      setCurrentPage,
                      hasMore = true
}) => {
  const [generatingReport, setGeneratingReport] = useState(false)
  const { isPm } = useSelector(userSelector)
  const [currentTask, setCurrentTask] = useState(null)
  const [currentTaskIndex, setCurrentTaskIndex] = useState(null)
  const [imagePreview, setImagePreview] = useState(null)
  const [showImagePreview, setShowImagePreview] = useState(null)
  const [ previewLoading, setPreviewLoading ] = useState(false)
  const [ postAssemblyDocs, setPostAssemblyDocs ] = useState([])
  const [withPreload, setWithPreload] = useState(false)
  const currentDocIndex = postAssemblyDocs.findIndex(preAssemblyDoc => imagePreview?.id === preAssemblyDoc.id)


  useEffect(() => {
    if (localStorage.getItem('preload') === null) {
      setWithPreload(true)
    } else if (isPm) {
      setWithPreload(localStorage.getItem('preload') === 'true')
    }
  }, [isPm])

  useEffect(() => {
    if (currentTask?.documents.length > 0) {
      const postAssembly = currentTask.documents.filter(doc => doc.type === 'Dokumentacja pomontażowa')

      setPostAssemblyDocs(postAssembly)
    }
  }, [currentTask])

  // const getReport = () => {
  //   setGeneratingReport(true)
  //   return api.get(
  //     `/SSingleOrders/Pdf/${projectId}`,
  //     {
  //       headers: {
  //         'X-TOKEN': localStorage.getItem('token'),
  //
  //       },
  //       params: {
  //         templates: [38]
  //       }
  //     }
  //   ).then(res => {
  //
  //     const pdf = res.data.result.data
  //     const linkSource = `data:application/pdf;base64,${pdf}`;
  //     const downloadLink = document.createElement("a");
  //     const fileName = res.data.result.name;
  //
  //     downloadLink.href = linkSource;
  //     downloadLink.download = fileName;
  //     downloadLink.click();
  //     setGeneratingReport(false)
  //
  //
  //   }, error => {
  //     toast.error('Nie udało się wygenerować raportu.')
  //     setGeneratingReport(false)
  //   })
  // }

  const getFile = (file) => {
    return api.get(
      `/Documents/FileBase64/${file.id}`,
      {
        headers: {
          'X-TOKEN': localStorage.getItem('token')
        }
      }
    )
  }

  const handlePrevPreview = async () => {

    if (currentDocIndex === 0 || currentDocIndex === -1) {
      if (currentTaskIndex === 0) {
        return null
      } else {
        setCurrentTaskIndex(currentTaskIndex - 1)
        const newTask = doneTasks[currentTaskIndex - 1]
        if (newTask) {
          setCurrentTask(newTask)
          const postAssembly = newTask.documents.filter(doc => doc.type === 'Dokumentacja pomontażowa')
          setPostAssemblyDocs(postAssembly)
          const newFile = postAssembly[postAssembly.length - 1]

          if (newFile) {
            setPreviewLoading(true)
            const newFileToPreview = await getFile(newFile).then(res => {
              setPreviewLoading(false)
              return ({...newFile, url: res?.data?.result.content, docType: res?.data?.result.type})
            })
            setImagePreview(newFileToPreview)
          } else {
            setImagePreview('unavailable')
          }
        }
      }
      return null
    } else {
      setPreviewLoading(true)
      const previousFile = postAssemblyDocs[currentDocIndex - 1]
      if (previousFile) {
        const newFileToPreview = await getFile(previousFile).then(res => {
          setPreviewLoading(false)
          return ({...previousFile, url: res?.data?.result.content, docType: res?.data?.result.type})
        })
        setImagePreview(newFileToPreview)
      } else {
        setPreviewLoading(false)
      }
    }
  }

  const handleNextPreview = async () => {
    if (currentDocIndex === postAssemblyDocs.length - 1) {
      if (!hasMore && currentTaskIndex === doneTasks.length - 1) {
        // is last task
        return null
      } else if (currentTaskIndex !== doneTasks.length - 1) {
        // setCurrentPage(currentPage + 1)
        setCurrentTaskIndex(currentTaskIndex + 1)
        const newTask = doneTasks[currentTaskIndex + 1]

        if (newTask) {
          setCurrentTask(newTask)
          const postAssembly = newTask.documents.filter(doc => doc.type === 'Dokumentacja pomontażowa')
          setPostAssemblyDocs(postAssembly)
          const newFile = postAssembly[0]

          if (newFile) {
            setPreviewLoading(true)
            const newFileToPreview = await getFile(newFile).then(res => {
              setPreviewLoading(false)
              return ({...newFile, url: res?.data?.result.content, docType: res?.data?.result.type})
            })
            setImagePreview(newFileToPreview)
          } else {
            setImagePreview('unavailable')
          }
        }
      } else {
        setCurrentPage(currentPage + 1)
      }
      return null
    } else {
      setPreviewLoading(true)
      const nextFile = postAssemblyDocs[currentDocIndex + 1]
      if (nextFile) {
        const newFileToPreview = await getFile(nextFile).then(res => {
          setPreviewLoading(false)
          return ({...nextFile, url: res?.data?.result.content, docType: res?.data?.result.type})
        })
        setImagePreview(newFileToPreview)
      } else {
        setPreviewLoading(false)
      }
    }
  }

  const arrowRightPress = useKeyPress('ArrowRight')
  const arrowLeftPress = useKeyPress('ArrowLeft')

  useEffect(() => {
    if (arrowRightPress && showImagePreview) {
      handleNextPreview()
    }
    if (arrowLeftPress && showImagePreview) {
      handlePrevPreview()
    }
  },[arrowRightPress, arrowLeftPress])

  return (
    <Box mt={1} p={2} style={{background: '#d7d7d7'}}>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <h1 style={{marginTop: '0'}}>Przegląd dokumentacji pomontażowej</h1>
        {/*{(isPm || hasPermissionToVendorPanel) && <Box width='250px'>*/}
        {/*  <Button disabled={generatingReport}*/}
        {/*          startIcon={generatingReport && <CircularProgress style={{height: '20px', width: '20px'}}/>}*/}
        {/*          onClick={getReport} fullWidth color='primary' variant='contained'>*/}
        {/*    Generuj raport pdf*/}
        {/*  </Button>*/}
        {/*</Box>}*/}
        {isPm && <FormControlLabel
          style={{background: 'transparent!important'}}
          control={
            <Switch
              checked={withPreload}
              onChange={() => {
                setWithPreload(!withPreload)
                localStorage.setItem('preload', !withPreload)
              }}
              name="download-images"
              color="primary"
            />
          }

          label="Pobieraj miniaturki automatycznie"
        />}
      </Box>
      <Divider />
      <InfiniteScroll
        next={() => {
          setCurrentPage(currentPage + 1)
        }}
        style={{overflow: 'hidden'}}
        hasMore={hasMore}
        loader={<div></div>}
        dataLength={doneTasks.length}>
          {doneTasks.map((task, index) => {
            return (
              <PLMTask
                key={task.id}
                updateTasks={updateTasks}
                task={task}
                setImagePreview={setImagePreview}
                setShowImagePreview={setShowImagePreview}
                projectId={projectId}
                setCurrentTask={setCurrentTask}
                setCurrentTaskIndex={setCurrentTaskIndex}
                taskIndex={index}
                withPreload={withPreload}
              />
            )
          })}
      </InfiniteScroll>

      <Dialog fullScreen open={showImagePreview} onClose={() => {
        setShowImagePreview(false)
      }}>
        <Box display='flex' alignItems='center' justifyContent='flex-end' mt={1}>
          <Button color='primary' variant='outlined' style={{height: '30px', marginRight: '16px'}} onClick={() => {
            var a = document.createElement("a"); //Create <a>
            a.href = imagePreview.url; //Image Base64 Goes here
            a.download = imagePreview.fileName; //File name Here
            a.click(); //Downloaded file
          }}>
            Pobierz
          </Button>
          <IconButton size='large' onClick={() => {
            setShowImagePreview(false)
          }}>
            <Close />
          </IconButton>
        </Box>
        <h3 style={{textAlign: 'center', margin: '8px'}}>
          {currentTask?.location?.route && `(${currentTask?.location.route})`} {currentTask?.location.city}, {currentTask?.location.street} {currentTask?.location.buildingNumber}
        </h3>
        {isPm && currentTask?.supplier && <h3
            style={{textAlign: 'center', color: 'black', margin: '1px'}}><span style={{fontWeight: 400}}>Technik:</span> {currentTask?.supplier}</h3>}
        {currentTask?.note && <h4
          style={{textAlign: 'center', margin: '1px', color: currentTask?.note ? 'red' : 'inherit'}}>{currentTask?.note}</h4>}
        <DialogContent>
          {imagePreview &&
          <div style={{width: '100%', height: '100%', position: 'relative', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            {!previewLoading && <Box style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <IconButton
                size='large'
                disabled={previewLoading}
                onClick={() => handlePrevPreview()}
              >
                <ArrowBackIos/>
              </IconButton>
            </Box>}
            {
              previewLoading
                ? <Box display='flex' alignItems='center' justifyContent='center' style={{height: '100%', width: '100%'}}><CircularProgress /></Box>
                : imagePreview === 'unavailable'
                ? <Box display='flex' alignItems='center' justifyContent='center' style={{height: '100%', width: '500px'}}>Brak dokumentów dla tej lokalizacji</Box>
                : imagePreview.docType.includes('image')
                ? <a style={{maxHeight: '100%', display: 'flex'}} onClick={e => e.preventDefault()} href={imagePreview?.url} download={imagePreview?.fileName || imagePreview?.title}>
                    <img style={{objectFit: 'contain', maxWidth: '100%'}} alt='preview' src={imagePreview?.url}/>
                  </a>
                : imagePreview.docType.includes('pdf')
                  ? <Grid item xs={12}>
                    <Box height='100%' display='flex' alignItems='center' flexDirection='column' justifyContent='center'>
                      <Box display='flex' justifyContent='center'>
                        <IconButton color='primary' onClick={() => {
                          var a = document.createElement("a"); //Create <a>
                          a.href = imagePreview.url; //Image Base64 Goes here
                          a.download = imagePreview.fileName; //File name Here
                          a.click(); //Downloaded file
                        }}>
                          <PictureAsPdf style={{fontSize: 80}} />
                        </IconButton>
                      </Box>
                      <p style={{textAlign: 'center', marginTop: '5px', wordBreak: 'break-all', cursor: 'pointer'}} onClick={() => {
                        var a = document.createElement("a"); //Create <a>
                        a.href = imagePreview.url; //Image Base64 Goes here
                        a.download = imagePreview.fileName; //File name Here
                        a.click(); //Downloaded file
                      }}>
                        {imagePreview.fileName}
                      </p>
                    </Box>
                  </Grid>
                  : <Grid item xs={12}>
                    <Box height='100%' display='flex' alignItems='center' flexDirection='column' justifyContent='center'>
                      <Box display='flex' justifyContent='center'>
                        <IconButton color='primary' onClick={() => {
                          var a = document.createElement("a"); //Create <a>
                          a.href = imagePreview.url; //Image Base64 Goes here
                          a.download = imagePreview.fileName; //File name Here
                          a.click(); //Downloaded file
                        }}>
                          <InsertDriveFile style={{fontSize: 80}} />
                        </IconButton>
                      </Box>
                      <p style={{textAlign: 'center', marginTop: '5px', wordBreak: 'break-all', cursor: 'pointer'}} onClick={() => {
                        var a = document.createElement("a"); //Create <a>
                        a.href = imagePreview.url; //Image Base64 Goes here
                        a.download = imagePreview.fileName; //File name Here
                        a.click(); //Downloaded file
                      }}>
                        {imagePreview.fileName}
                      </p>
                    </Box>
                  </Grid>

            }
            {!previewLoading && <Box style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            >
              <IconButton
                size='large'
                disabled={previewLoading || (currentDocIndex === postAssemblyDocs.length - 1 && currentTaskIndex === doneTasks.length - 1)}
                onClick={() => handleNextPreview()}
              >
                <ArrowForwardIos/>
              </IconButton>
            </Box>}
          </div>
          }
        </DialogContent>
      </Dialog>


    </Box>
  )
}

export default PLMSection
