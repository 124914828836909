import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
  setStartedPage,
  projectsSelector,
  getProjectsFilteredByLocations
} from "./ProjectsSlice";
import {Box, Divider, Grid, IconButton, Button, Fade, TextField} from '@material-ui/core'
import {Autocomplete} from "@material-ui/lab";
import {ArrowBack, ArrowForward} from "@material-ui/icons";
import {userSelector} from "../User/UserSlice";
import Pagination from '@material-ui/lab/Pagination';
import {Link, useHistory} from "react-router-dom";
import ProjectByLocationTile from "../../common/Components/ProjectByLocationTile";
import {Helmet} from "react-helmet";

const Locations = () => {
  const dispatch = useDispatch()
  const {started, filteredByLocations} = useSelector(projectsSelector)
  const {fetchedUser, locations} = useSelector(userSelector)

  const locationsArray = JSON.parse(localStorage.getItem('locationsArray'))

  const [locationsSearchValue, setLocationsSearchValue] = useState([])
  const [selectedLocations, setSelectedLocations] = useState(locationsArray || [])

  useEffect(() => {
    dispatch(getProjectsFilteredByLocations({ locations: selectedLocations, page: filteredByLocations.page }))
  }, [filteredByLocations.page])

  useEffect(() => {
    dispatch(getProjectsFilteredByLocations({ locations: selectedLocations, page: filteredByLocations.page })).then(() => {
      setLocationsSearchValue(selectedLocations)
    })
  }, [selectedLocations])

  const locationsOptions = locations.map(location => ({
    label: `${location.name} (${location.street} ${location.buildingNumber}${location.flatNumber ? `/${location.flatNumber}` : ''}, ${location.postalCode}, ${location.city})`,
    value: location.id
  }))

  return (
    <Fade in={fetchedUser}>
      <Box textAlign='left' p={2}>
        <Helmet>
          <title>VPT - Historia prac na lokalizacji</title>
        </Helmet>
        <Box mb={2}>
          <Link style={{textDecoration: 'none'}} to={'/'}>
            <Button
              startIcon={<ArrowBack />}
              color='secondary'
              style={{height: '24px'}}
            >
              Wróc do listy projektów
            </Button>
          </Link>
        </Box>
        <Box mb={5}>
          <Grid container alignItems='center'>
            <Grid item xs={12} md={6} lg={4}>
              <Box display='flex' alignItems='center' mb={2}>
                <Autocomplete
                  fullWidth
                  multiple
                  filterSelectedOptions
                  id="tags-outlined"
                  noOptionsText='Brak wyników'
                  value={[...locationsOptions.filter(option => selectedLocations.includes(option.value))]}
                  options={locationsOptions.filter(option => !selectedLocations.includes(option.value))}
                  getOptionLabel={(option) => option.label}
                  onChange={(ev, values) => {
                    const locationsArray = values.map(({value}) => value)
                    setSelectedLocations(locationsArray)
                    return values
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Wybierz lokalizacje"
                    />
                  )}
                />
                <IconButton
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    dispatch(getProjectsFilteredByLocations({ locations: selectedLocations, page: filteredByLocations.page })).then(() => {
                      setLocationsSearchValue(selectedLocations)
                      localStorage.setItem('locationsArray', JSON.stringify(selectedLocations))
                    })
                  }}
                  style={{marginLeft: '10px'}}
                >
                  <ArrowForward/>
                </IconButton>
              </Box>
            </Grid>
          </Grid>
          <Divider />
        </Box>
        <Box>
          <h2>
            Projekty według lokalizacji:
          </h2>
          {
            locationsSearchValue.length === 0 ? <p style={{margin: '20px 0'}}>Wybierz jedną lub więcej lokalizacji, aby wyświetlić powiązane z nimi projekty.</p>
              : filteredByLocations.recordsList.length === 0
                ? <p style={{margin: '20px 0 20px 10px'}}>Brak wyników</p>
                : filteredByLocations.recordsList.map(
                  item => {
                    return <ProjectByLocationTile key={item.id} project={item} />
                  }
                )
          }
          <Divider />
          <Box mt={2} display='flex' justifyContent='flex-end'>
            <Pagination shape='rounded' page={started.page} onChange={(event, page) => dispatch(setStartedPage(page))} count={started.lastPage} />
          </Box>
        </Box>
      </Box>
    </Fade>
  )
}

export default Locations
