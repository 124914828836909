import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userSelector, clearState } from './UserSlice';
import {Route, Switch, useHistory, Redirect} from 'react-router-dom';
import Header from "../../common/Components/Header";
import {Box, Fade} from "@material-ui/core";
import Projects from "../Projects/Projects";
import Locations from "../Projects/Locations";
import {getUser} from "./UserSlice";
import PmTasks from "../Projects/PmTasks";
import ClientProjectDetails from "../Projects/ClientProjectDetails";
import SupplierTasks from "../Projects/SupplierTasks";

const Dashboard = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { isFetching, isError, isPm } = useSelector(userSelector);

  useEffect(() => {
      dispatch(getUser())
  }, []);

  useEffect(() => {
    // dispatch(getStartedProjects({}))
    // dispatch(getDoneOrInvoicedProjects({}))

    if (isError) {
      dispatch(clearState());
      history.push('/login');
    }
  }, [isError, dispatch, history]);

  return (
    <div>
      {/*{isFetching ? (*/}
      {/*  <Loader type="Puff" color="#00BFFF" height={100} width={100} />*/}
      {/*) : (*/}
        <Fragment>
          <Fade in>
            <Box minHeight='100vh'>
              <Header />
              <Switch>
                <Route exact path='/' component={Projects} />
                <Route path='/locations' component={Locations}/>
                <Route path='/tasks/:projectId' component={PmTasks}/>
                <Route path='/supplier-tasks/:projectId' component={SupplierTasks}/>
                <Route path='/project/:projectId' component={ClientProjectDetails}/>
                <Redirect to='/' />
              </Switch>
            </Box>
          </Fade>
        </Fragment>
      {/*)}*/}
    </div>
  );
};

export default Dashboard;
