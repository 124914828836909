import { createMuiTheme } from '@material-ui/core/styles'

export const COLOR = {
  primary: '#0685CE',
  background: '#F2F2F2',
  lightGrey: '#BDBDBD',
  success: '#07bc0c',
  warning: '#f1c40f',
  linkBlue: '#2F80ED',
  orange: '#FF7722'
}

export const DEPARTURES_STATUS_COLORS = {
  'Wstępny': COLOR.lightGrey,
  'Potwierdzony': COLOR.success,
  'Rezerwacja': COLOR.warning,
  'Przesunięty': COLOR.orange
}

export const VACATIONS_STATUS_COLORS = {
  'Zaakceptowano': COLOR.success,
  'Do akceptacji': COLOR.orange
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: COLOR.primary
    },
    secondary: {
      main: "#000"
    },
    error: {
      main: "#ff0033"
    },
    background: {
      default: COLOR.background
    },
    text: {
      primary: COLOR.primary
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '8px',
        padding: '10px',
        '&:hover': {
          color: '#fff',
          backgroundColor: '#0685CE!important'
        }
      },
      outlined: {
        padding: '10px'
      },

    },
    MuiInputBase: {
      root: {
        backgroundColor: 'transparent',
        color: '#000'
      }
    },
    MuiFormLabel: {
      root: {
        backgroundColor: 'transparent'
      }
    },
    MuiFormControlLabel: {
      label: {
        background: 'transparent'
      }
    }
  },
  typography: {
    subtitle1: {
      fontSize: 12
    },
    subtitle2: {
      fontSize: 11
    },
    body1: {
      backgroundColor: COLOR.background,
      color: COLOR.primary
    }
  }
})

export default theme
