import React, { Fragment, useEffect } from 'react';
// import PlatformaTechniczna from '../../assets/images/PlatformaSVG.svg'
import PlatformaTechniczna from '../../assets/images/ProjectTracking.svg'
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { loginUser, userSelector, clearState } from './UserSlice';
import { toast } from "react-toastify";
import {useHistory, useParams} from 'react-router-dom';
import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import { ArrowForwardIos, PersonOutlined } from "@material-ui/icons";
import clsx from "clsx";

import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import {Helmet} from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
    background: '#FFF'
  },
}));

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const { register, errors, handleSubmit, control } = useForm();
  const { isFetching, isSuccess, isError, errorMessage, isPm, isAccount } = useSelector(
    userSelector
  );
  const onSubmit = (data) => {
    dispatch(loginUser(data));
  };

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, [dispatch]);

  useEffect(() => {

    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }

    if (isSuccess) {
      toast.success('Zalogowano')
      dispatch(clearState());
      if (window.requestedUrl) {
        history.push(window.requestedUrl)
        window.requestedUrl = ''
      } else {
        history.push(`/`)
      }
    }
  }, [isError, isSuccess, dispatch, errorMessage, history]);

  const [values, setValues] = React.useState({
    user: '',
    password: '',
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Fragment>
      <Helmet defer={false}>
        <title>VPT - Logowanie</title>
      </Helmet>
      <Box display='flex' height='100vh' width='100%' justifyContent='center' alignItems='center'>
        <Box maxWidth={['100%', '80%', '40%', '30%']} mt={-45} p={[2, 2, 5]}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            method='POST'
          >
            <Grid container justify='center'>
              <Grid item xs={12}>
                <Box m={10}>
                  <img src={PlatformaTechniczna} alt='logo' style={{maxWidth: '400px', marginTop: '100px'}} />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <FormControl required className={clsx(classes.margin, classes.textField)} variant='outlined'>
                  <InputLabel htmlFor='user'>Użytkownik</InputLabel>
                  <Controller
                    name='user'
                    control={control}
                    type='text'
                    defaultValue=''
                    as={
                      <OutlinedInput
                        autoComplete='username'
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton
                              style={{backgroundColor: 'transparent', cursor: 'default'}}
                              disableRipple
                              disableTouchRipple
                              disableFocusRipple
                              aria-label="user"
                              edge='end'
                            >
                              <PersonOutlined/>
                            </IconButton>
                          </InputAdornment>
                        }
                        labelWidth={85}
                      />
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl required className={clsx(classes.margin, classes.textField)} variant='outlined'>
                  <InputLabel htmlFor='password'>Hasło</InputLabel>
                  <Controller
                    name='password'
                    control={control}
                    defaultValue=''
                    type={values.showPassword ? 'text' : 'password'}
                    as={
                      <OutlinedInput
                        autoComplete='current-password'
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge='end'
                            >
                              {values.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                        labelWidth={45}
                      />
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button type='submit' disabled={isFetching} fullWidth color='primary' variant='contained' endIcon={<ArrowForwardIos/>}>
                  Zaloguj
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </Fragment>
  );
};

export default Login;
