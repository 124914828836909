import {Link, Redirect, Route, Switch, useHistory, useParams} from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress, Dialog,
  DialogContent,
  Divider,
  Grid, IconButton,
  LinearProgress,
  Tab
} from "@material-ui/core";
import {ArrowBack, Search} from "@material-ui/icons";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getProject, getTasks, getTasksPLM, setStartedPage} from "./ProjectsSlice";
import TaskTile from "../../common/Components/TaskTile";
import TaskDetails from "../../common/Components/TaskDetails";
import Pagination from "@material-ui/lab/Pagination";
import {toast} from "react-toastify";
import {TabPanel, TabList, TabContext} from "@material-ui/lab";
import PLMSection from "./PLMSection";
import {api} from "../../api";
import { saveAs } from 'file-saver'
import {setActiveTab, userSelector} from "../User/UserSlice";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import {Helmet} from "react-helmet";
import {scrollToTopSmooth} from "../../helpers/utils";

// const projectForPm = {
//   number: '236-07-20-AGAJ-AESQ', //Numer projektu
//   implementationDate: '2021-05-20', //Termin wykonania
//   contractor: 'Netinteractive Spółka Z Ograniczoną Odpowiedzialnością', //Kontrahent
//   suppliers: ['Dostawca1 sp. z o. o.', 'Dostawca2  sp. z o. o.'] //Wszyscy dostawcy (nazwy) powiazani z projektem
// }

const SupplierTasks = () => {
  const { activeTab } = useSelector(userSelector)

  const [projectForPm, setProjectForPm] = useState(null)
  const [doneTasks, setDoneTasks] = useState([])
  const [toDoTasks, setToDoTasks] = useState([])
  const [doneTasksPLM, setDoneTasksPLM] = useState([])
  const [toDoTasksPLM, setToDoTasksPLM] = useState([])
  const [doneTasksPage, setDoneTasksPage] = useState(1)
  const [toDoTasksPage, setToDoTasksPage] = useState(1)
  const [doneTasksLastPage, setDoneTasksLastPage] = useState(1)
  const [toDoTasksLastPage, setToDoTasksLastPage] = useState(1)
  const dispatch = useDispatch()
  const history = useHistory()
  const { projectId } = useParams()
  const [generatingReport, setGeneratingReport] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const [ currentPage, setCurrentPage ] = useState(1)
  const [locationSearchValue, setLocationSearchValue] = useState('')

  const [toDoTasksFetched, setToDoTasksFetched] = useState(false)
  const [doneTasksFetched, setDoneTasksFetched] = useState(false)

  const [value, setValue] = React.useState(localStorage.getItem('activeTab') || activeTab);

  useEffect(() => {
    dispatch(getProject(projectId)).then(res => setProjectForPm(res.payload))
  }, [])

  useEffect(() => {
    getDoneTasks()
  }, [doneTasksPage, history.location])

  useEffect(() => {
    getToDoTasks()
  }, [toDoTasksPage, history.location])

  useEffect(() => {
    if (locationSearchValue) {
      const timeoutId = setTimeout(() => {
        updateTasks()
        getAllTasksPLMSearchVal()
      }, 1000)
      return () => clearTimeout(timeoutId)
    } else {
      getAllTasksPLM()
    }
  }, [history.location, currentPage, locationSearchValue])

  useState(() => {
    scrollToTopSmooth()
  }, [window.location])

  const filterTasks = () => {
    dispatch(getTasks({projectId, status: 'Do realizacji', page: toDoTasksPage, perPage: 100, location: locationSearchValue})).then(res => {
      setToDoTasks(res.payload.tasks)
      setToDoTasksPLM(res.payload.tasks)
      setToDoTasksLastPage(res.payload.lastPage)
      setToDoTasksFetched(true)
    })

    dispatch(getTasks({projectId, status: 'Zrealizowane', page: doneTasksPage, perPage: 100, location: locationSearchValue})).then(res => {
      setDoneTasks(res.payload.tasks)
      setDoneTasksPLM(res.payload.tasks)
      setDoneTasksLastPage(res.payload.lastPage)
      setDoneTasksFetched(true)
    })
  }

  useEffect(() => {
    dispatch(setActiveTab(value))
    localStorage.setItem('activeTab', value)
  }, [value])

  const getAllTasksPLM = () => {
    dispatch(getTasksPLM({projectId, status: 'Do realizacji', page: currentPage, location: locationSearchValue})).then(res => {
      setToDoTasksPLM([...currentPage === 1 ? [] : toDoTasksPLM, ...res.payload.tasks])
    })
    dispatch(getTasksPLM({projectId, status: 'Zrealizowane', page: currentPage, location: locationSearchValue})).then(res => {
      setDoneTasksPLM([...currentPage === 1 ? [] : doneTasksPLM, ...res.payload.tasks])
    })
  }

  const getAllTasksPLMSearchVal = () => {
    dispatch(getTasksPLM({projectId, status: 'Do realizacji', page: 1, location: locationSearchValue})).then(res => {
      setToDoTasksPLM([...res.payload.tasks])
    })
    dispatch(getTasksPLM({projectId, status: 'Zrealizowane', page: 1, location: locationSearchValue})).then(res => {
      setDoneTasksPLM([...res.payload.tasks])
    })
  }

  const getToDoTasks = () => {
    dispatch(getTasks({projectId, status: 'Do realizacji', page: toDoTasksPage, location: locationSearchValue})).then(res => {
      setToDoTasks(res.payload.tasks)
      setToDoTasksLastPage(res.payload.lastPage)
      setToDoTasksFetched(true)
    })
  }

  const getDocumentation = () => {
    return api.get(
      `/SSingleOrders/AssemblyTasksZip/${projectId}`,
      {
        responseType: 'arraybuffer',
        headers: {
          'X-TOKEN': localStorage.getItem('token')
        }
      }
    )
  }

  const getDoneTasks = () => {
    dispatch(getTasks({projectId, status: 'Zrealizowane', page: doneTasksPage, location: locationSearchValue})).then(res => {
      setDoneTasks(res.payload.tasks)
      setDoneTasksLastPage(res.payload.lastPage)
      setDoneTasksFetched(true)
    })
  }

  const updateTasks = () => {
    getDoneTasks()
    getToDoTasks()
  }

  const getReport = () => {
    setGeneratingReport(true)
    return api.get(
      `/SSingleOrders/Pdf/${projectId}`,
      {
        headers: {
          'X-TOKEN': localStorage.getItem('token'),

        },
        params: {
          templates: [38]
        }
      }
    ).then(res => {

      const pdf = res.data.result.data
      const linkSource = `data:application/pdf;base64,${pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = res.data.result.name;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      setGeneratingReport(false)


    }, error => {
      toast.error('Nie udało się wygenerować raportu.')
      setGeneratingReport(false)
    })
  }

  if (!projectForPm) {
    return <Box m={10}>
      <CircularProgress />
    </Box>
  }

  return (
    <>
      <Helmet defer={false}>
        <title>VPT - {projectForPm.subject} - {projectForPm.number}</title>
      </Helmet>
      <Switch>
      <Route exact path={`/supplier-tasks/${projectId}`} render={() => {
        return <Box textAlign='left' p={2}>
          <TabContext value={value}>
            <Box mb={2} display='flex' justifyContent='space-between'>
              <Link style={{textDecoration: 'none'}} to={'/'}>
                <Button
                  startIcon={<ArrowBack />}
                  color='secondary'
                  style={{height: '24px'}}
                >
                  Wróc do listy projektów
                </Button>
              </Link>
            </Box>

            <Box mb={2} display='flex' justifyContent='center'>
              <Box pr={2}>
                <Button
                  fullWidth
                  variant='outlined'
                  // disabled={task?.documents.length === 0}
                  color='primary'
                  onClick={() => {
                    setDownloading(true)
                    getDocumentation().then(res => {
                      const blob = new Blob([res.data], {type: "octet/stream"});
                      const fileName = `VPT Dokumentacja ${projectForPm.number}.zip`;
                      saveAs(blob, fileName);
                      setDownloading(false)
                    })
                  }}
                >
                  Pobierz dokumentację
                </Button>
              </Box>

              {/*<Box pr={2}>*/}
              {/*  <Button disabled={generatingReport} startIcon={generatingReport && <CircularProgress style={{height: '20px', width: '20px'}} />} onClick={getReport} color='primary' variant='outlined'>*/}
              {/*    Generuj raport pdf*/}
              {/*  </Button>*/}
              {/*</Box>*/}

              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <ButtonGroup style={{height: '46px'}} size="large" aria-label="large button group">
                  <Button
                    color='primary'
                    variant={value === "1" ? "contained" : "outlined"}
                    onClick={() => setValue("1")}
                  >
                    Tryb PoS
                  </Button>
                  <Button
                    color='primary'
                    variant={value === "2" ? "contained" : "outlined"}
                    onClick={() => setValue("2")}
                  >
                    Tryb PLM
                  </Button>
                </ButtonGroup>
              </Box>
            </Box>


            <Box>
              <Box mb={2}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        <Grid container>
                          <Grid item xs={3} xl={2}>
                            <h3 style={{textAlign: 'right'}}>Numer projektu:</h3>
                          </Grid>
                          <Grid item xs={9} xl={10}>
                            <h3 style={{marginLeft: '16px'}}>{projectForPm.number || '-'}</h3>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Grid container>

                      <Grid item xs={3} xl={2}>
                        <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Projekt:</b></p>
                      </Grid>
                      <Grid item xs={9} xl={10}>
                        <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>{projectForPm.subject || '-'}</p>
                      </Grid>

                      <Grid item xs={3} xl={2}>
                        <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Termin realizacji:</b></p>
                      </Grid>
                      <Grid item xs={9} xl={10}>
                        <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>{projectForPm.implementationDate || '-'}</p>
                      </Grid>

                      <Grid item xs={3} xl={2}>
                        <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Wyszukaj po lokalizacji:</b></p>
                      </Grid>
                      <Grid item xs={9} xl={10}>
                        <Box display='flex' alignItems='center'>
                          <h5 style={{marginRight: '15px', width: 'fit-content', whiteSpace: 'no-wrap'}}>
                          </h5>
                          <OutlinedInput
                            style={{height: '40px', width: '100%'}}
                            type='text'
                            value={locationSearchValue}
                            onChange={(e) => {
                              if (currentPage !== 1) {
                                setCurrentPage(1)
                              }
                              if (toDoTasksPage !== 1) {
                                setToDoTasksPage(1)
                              }
                              if (doneTasksPage !== 1) {
                                setDoneTasksPage(1)
                              }
                              setLocationSearchValue(e.target.value)
                            }}
                            onKeyPress={e => {
                              if (e.key === 'Enter') {
                                filterTasks()
                                //   dispatch(getStartedProjects({ number: e.target.value, contractor: contractorSearchValue, page: started.page }))
                                //   dispatch(getDoneOrInvoicedProjects({ number: e.target.value, contractor: contractorSearchValue, page: doneOrInvoiced.page }))
                              }
                            }}
                          />
                          <IconButton
                            variant='contained'
                            color='primary'
                            onClick={() => {
                              filterTasks()
                              // dispatch(getStartedProjects({ number: projectSearchValue, contractor: contractorSearchValue, page: started.page }))
                              // dispatch(getDoneOrInvoicedProjects({ number: projectSearchValue, contractor: contractorSearchValue, page: doneOrInvoiced.page }))
                            }}
                            style={{marginLeft: '10px'}}
                          >
                            <Search />
                          </IconButton>
                        </Box>
                      </Grid>

                      {/*<Grid item xs={3} xl={2}>*/}
                      {/*  <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Link dla klienta:</b></p>*/}
                      {/*</Grid>*/}
                      {/*<Grid item xs={9} xl={10}>*/}
                      {/*  <Box display='flex' alignItems='center'>*/}
                      {/*    <p*/}
                      {/*      onClick={() => {*/}
                      {/*        copyToClipboard(`${window.location.origin}/project/${projectId}`)*/}
                      {/*        toast.info('Skopiowano do schowka')*/}
                      {/*      }}*/}
                      {/*      style={{cursor: 'pointer', marginLeft: '16px', marginRight: '8px', marginTop: '8px', marginBottom: '8px'}}>*/}
                      {/*      {`${window.location.origin}/project/${projectId}`}*/}
                      {/*    </p>*/}
                      {/*    <Button*/}
                      {/*      onClick={() => {*/}
                      {/*        copyToClipboard(`${window.location.origin}/project/${projectId}`)*/}
                      {/*        toast.info('Skopiowano do schowka')*/}
                      {/*      }}*/}
                      {/*    >*/}
                      {/*      kopiuj*/}
                      {/*    </Button>*/}
                      {/*  </Box>*/}
                      {/*</Grid>*/}

                    </Grid>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <Grid container>
              {/*        <Grid item xs={3} xl={2}>*/}
              {/*          <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Kontrahent:</b></p>*/}
              {/*        </Grid>*/}
              {/*        <Grid item xs={9} xl={10}>*/}
              {/*          <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>{projectForPm.contractor || '-'}</p>*/}
              {/*        </Grid>*/}

              {/*        <Grid item xs={3} xl={2}>*/}
              {/*          <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Kontakt:</b></p>*/}
              {/*        </Grid>*/}
              {/*        <Grid item xs={9} xl={10}>*/}
              {/*          <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>*/}
              {/*            {*/}
              {/*              projectForPm.contact ? <span>*/}
              {/*  {projectForPm.contact.name},&nbsp;*/}
              {/*                  <a href={`mailto: ${projectForPm.contact.email}`}>{projectForPm.contact.email}</a>&nbsp;*/}
              {/*                  <a href={`tel: ${projectForPm.contact.phone}`}>{projectForPm.contact.phone}</a>*/}
              {/*</span>*/}
              {/*                : '-'*/}
              {/*            }*/}
              {/*          </p>*/}
              {/*        </Grid>*/}

                      {/*<Grid item xs={3} xl={2}>*/}
                      {/*  <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Nadzór:</b></p>*/}
                      {/*</Grid>*/}
                      {/*<Grid item xs={9} xl={10}>*/}
                      {/*  {*/}
                      {/*    projectForPm.supervisors.length === 0*/}
                      {/*      ? <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>-</p>*/}
                      {/*      : projectForPm.supervisors.map(supervisor => {*/}
                      {/*        return (*/}
                      {/*          <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>*/}
                      {/*<span>*/}
                      {/*  {supervisor.name},&nbsp;*/}
                      {/*  <a href={`mailto: ${supervisor.email}`}>{supervisor.email}</a>&nbsp;*/}
                      {/*  <a href={`tel: ${supervisor.phone}`}>{supervisor.phone}</a>*/}
                      {/*</span>*/}
                      {/*          </p>*/}
                      {/*        )*/}
                      {/*      })}*/}
                      {/*</Grid>*/}

                      {/*<Grid item xs={3} xl={2}>*/}
                      {/*  <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Dostawcy:</b></p>*/}
                      {/*</Grid>*/}
                      {/*<Grid item xs={9} xl={10}>*/}
                      {/*  {projectForPm.suppliers.map(supplier => {*/}
                      {/*    return <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>*/}
                      {/*      {supplier}*/}
                      {/*    </p>*/}
                      {/*  })}*/}
                      {/*  /!*<p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>*!/*/}
                      {/*  /!*  {projectForPm.suppliers.length === 0 ? '-' : projectForPm.suppliers.join(', ')}*!/*/}
                      {/*  /!*</p>*!/*/}
                      {/*</Grid>*/}

                    </Grid>
                  </Grid>


                </Grid>
              </Box>
            </Box>
            <Divider />
            {value === "2" && <Box>
              <h4>Postęp projektu:</h4>
              <Box height='100%' display='flex' alignItems='center'>
                <div style={{height: '40px', width: '100%', position: 'relative'}}>
                  <p
                    style={{
                      margin: 0,
                      position: 'absolute',
                      color: '#fff',
                      fontWeight: 900,
                      zIndex: 1,
                      height: '20px',
                      fontSize: '12px',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    {projectForPm.ready}/{projectForPm.total} {projectForPm.total === 0 ? 0 : (projectForPm.ready / projectForPm.total * 100).toFixed(0)}%
                  </p>
                  <LinearProgress style={{height: '20px', width: '100%', borderRadius: '8px'}} variant='determinate'
                                  value={projectForPm.total === 0 ? 0 : (projectForPm.ready / projectForPm.total * 100).toFixed(0)}/>
                </div>
              </Box>
            </Box>}

            {value === '1' && <Box>
              <Box mt={5}>
                <h3>Zadania niezrealizowane:</h3>
                <Divider/>
                {toDoTasks.length === 0 ? <p>Brak zadań niezrealizowanych</p> : <Box>
                  <Grid container>
                    {toDoTasks.map(task => <Grid key={task.id} item xs={12}><TaskTile projectId={projectId} task={task}/></Grid>)}
                  </Grid>
                  <Box mt={2} display='flex' justifyContent='flex-end'>
                    <Pagination shape='rounded' page={toDoTasksPage} onChange={(event, page) => setToDoTasksPage(page)}
                                count={toDoTasksLastPage}/>
                  </Box>
                </Box>
                }
              </Box>
              <Box mt={5}>
                <h3>Zadania zrealizowane:</h3>
                <Divider/>
                {doneTasks.length === 0 ? <p>Brak zadań zrealizowanych</p> : <Box>
                  <Grid container>
                    {doneTasks.map(task => <Grid key={task.id} item xs={12}><TaskTile projectId={projectId} task={task}/></Grid>)}
                  </Grid>
                  <Box mt={2} display='flex' justifyContent='flex-end'>
                    <Pagination shape='rounded' page={doneTasksPage} onChange={(event, page) => setDoneTasksPage(page)}
                                count={doneTasksLastPage}/>
                  </Box>
                </Box>
                }
              </Box>
            </Box>}
            {/*<TabPanel value="1" style={{padding: 0}}>*/}
            {/*</TabPanel>*/}
            <TabPanel value="2" style={{padding: 0}}>
              <PLMSection currentPage={currentPage} setCurrentPage={setCurrentPage} updateTasks={updateTasks} doneTasks={[...toDoTasksPLM, ...doneTasksPLM]} projectId={projectId} />
            </TabPanel>
          </TabContext>
          <Dialog maxWidth='xl' open={downloading}>
            <DialogContent>
              <h3>Trwa pobieranie dokumentacji...</h3>
              <Box display='flex' justifyContent='center' m={4}>
                <CircularProgress />
              </Box>
            </DialogContent>
          </Dialog>
        </Box>
      }}
      />
      <Route exact path={`/supplier-tasks/${projectId}/:taskId`} render={() => <TaskDetails tasks={[...doneTasks, ...toDoTasks]} doneTasksFetched={doneTasksFetched} toDoTasksFetched={toDoTasksFetched} projectId={projectId} />} />
    </Switch>
    </>
  )
}

export default SupplierTasks
