import React, {useEffect, useState} from 'react'
import {Box, Button, Dialog, DialogContent, Divider, Grid, LinearProgress, CircularProgress} from "@material-ui/core";
import {useSelector} from "react-redux";
import {userSelector} from "../../features/User/UserSlice";
import {api} from "../../api";
import IconButton from "@material-ui/core/IconButton";
import {ArrowBackIos, ArrowForwardIos, Close, InsertDriveFile, PictureAsPdf} from "@material-ui/icons";
import {useHistory} from "react-router-dom";
import {DONE_STATUS, INVOICED_STATUS} from "../../features/Projects/ProjectsSlice";
import TaskDocument from "./TaskDocument";

const ProjectByLocationTile = ({project}) => {

  const history = useHistory()
  const { isPm } = useSelector(userSelector)
  const [ imagePreview, setImagePreview ] = useState(false)
  const [ showImagePreview, setShowImagePreview ] = useState(false)
  const [ preAssemblyDocs, setPreAssemblyDocs ] = useState([])
  const [ postAssemblyDocs, setPostAssemblyDocs ] = useState([])
  const [ previewLoading, setPreviewLoading ] = useState(false)

  const [ preAssemblyLimit, setPreAssemblyLimit ] = useState(30)
  const [ postAssemblyLimit, setPostAssemblyLimit ] = useState(30)


  const getFile = (file) => {
    return api.get(
      `/Documents/FileBase64/${file.id}`,
      {
        headers: {
          'X-TOKEN': localStorage.getItem('token')
        }
      }
    )
  }

  useEffect(() => {
    if (project?.documents?.length > 0) {
      if (project?.documents.length > 0) {
        const preAssembly = project.documents.filter(doc => doc.type === 'Dokumentacja przedmontażowa')
        const postAssembly = project.documents.filter(doc => doc.type === 'Dokumentacja pomontażowa')

        setPreAssemblyDocs(preAssembly)
        setPostAssemblyDocs(postAssembly)
      }
    }
  }, [project])

  const handlePrevPreview = async () => {
    if (imagePreview.type === 'Dokumentacja przedmontażowa') {
      const currentDocIndex = preAssemblyDocs.findIndex(preAssemblyDoc => imagePreview?.id === preAssemblyDoc.id)
      if (currentDocIndex === 0) {
        return null
      } else {
        setPreviewLoading(true)
        const previousFile = preAssemblyDocs[currentDocIndex - 1]
        const newFileToPreview = await getFile(previousFile).then(res => {
          setPreviewLoading(false)
          return ({...previousFile, url: res?.data?.result.content, docType: res?.data?.result.type})
        })
        setImagePreview(newFileToPreview)
      }

    } else if (imagePreview.type === 'Dokumentacja pomontażowa') {
      const currentDocIndex = postAssemblyDocs.findIndex(preAssemblyDoc => imagePreview?.id === preAssemblyDoc.id)
      if (currentDocIndex === 0) {
        return null
      } else {
        setPreviewLoading(true)
        const previousFile = postAssemblyDocs[currentDocIndex - 1]
        const newFileToPreview = await getFile(previousFile).then(res => {
          setPreviewLoading(false)
          return ({...previousFile, url: res?.data?.result.content, docType: res?.data?.result.type})
        })
        setImagePreview(newFileToPreview)
      }
    }
  }

  const handleNextPreview = async () => {
    if (imagePreview.type === 'Dokumentacja przedmontażowa') {
      const currentDocIndex = preAssemblyDocs.findIndex(preAssemblyDoc => imagePreview?.id === preAssemblyDoc.id)
      if (currentDocIndex === preAssemblyDocs.length - 1) {
        return null
      } else {
        setPreviewLoading(true)
        const nextFile = preAssemblyDocs[currentDocIndex + 1]
        const newFileToPreview = await getFile(nextFile).then(res => {
          setPreviewLoading(false)
          return ({...nextFile, url: res?.data?.result.content, docType: res?.data?.result.type})
        })
        setImagePreview(newFileToPreview)
      }

    } else if (imagePreview.type === 'Dokumentacja pomontażowa') {
      const currentDocIndex = postAssemblyDocs.findIndex(preAssemblyDoc => imagePreview?.id === preAssemblyDoc.id)
      if (currentDocIndex === postAssemblyDocs.length - 1) {
        return null
      } else {
        setPreviewLoading(true)
        const nextFile = postAssemblyDocs[currentDocIndex + 1]
        const newFileToPreview = await getFile(nextFile).then(res => {
          setPreviewLoading(false)
          return ({...nextFile, url: res?.data?.result.content, docType: res?.data?.result.type})
        })
        setImagePreview(newFileToPreview)
      }
    }
  }

  return (
    <Box>
      <Divider />
      <Grid container>
        <Grid item xs={isPm ? 7 : 8} lg={isPm ? 9 : 10}>
          <Box pr={2}>
            <h3>{project.number}</h3>
            <p>
              {`Termin umowy: ${project.contractTerm || 'brak'} || ${project.description}`}
            </p>
            <p>Status: <b>{project.status}</b></p>
          </Box>
        </Grid>
        {isPm && <Grid item xs={2}>
          <Box pr={2} height='100%' display='flex' alignItems='center'>
            <div style={{height: '20px', width: '100%', position: 'relative'}}>
              <p
                style={{margin: 0, position: 'absolute', color: '#fff', fontWeight: 900, zIndex: 1, fontSize: '12px', height: '20px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
              >
                {project.ready}/{project.total} {project.total === 0 ? 0 : (project.ready / project.total * 100).toFixed(0)}%
              </p>
              <LinearProgress style={{height: '20px', width: '100%', borderRadius: '8px'}} variant='determinate' value={project.total === 0 ? 0 : (project.ready / project.total * 100).toFixed(0)}/>
            </div>
          </Box>
        </Grid>}
        <Grid item xs={3} lg={isPm ? 1 : 2}>
          <Box height='100%' display='flex' alignItems='center'>
            <Button
              fullWidth
              color={project.status === INVOICED_STATUS || project.status === DONE_STATUS ? 'default' : 'primary'}
              variant='contained'
              href={isPm ? (`/tasks/${project.id}`) : (`/project/${project.id}`)}
              style={{ height: '24px' }}
              // onClick={() => isPm ? history.push(`/tasks/${project.id}`) : history.push(`/project/${project.id}`)}
            >
              {isPm ? 'Zadania' : 'Szczegóły'}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container style={{borderBottom: '1px solid rgba(0,0,0,0.12)', borderTop: '1px solid rgba(0,0,0,0.12)'}}>
        <Grid item xs={6}>
          <Box boxSizing='border-box' p={3} mb={5}>
            <h3 style={{marginTop: 0}}>Dokumentacja przedmontażowa:</h3>
            {preAssemblyDocs.length === 0
              ? <p>Brak dokumentów</p>
              : <Grid container spacing={2} alignItems='center'>
                {preAssemblyDocs.slice(0, preAssemblyLimit).map((doc, index) => {
                  return <TaskDocument
                    key={doc.id}
                    doc={doc}
                    setImagePreview={setImagePreview}
                    setShowImagePreview={setShowImagePreview}
                  />
                })}
              </Grid>}
            {preAssemblyDocs.length > 0 && <Box display='flex' justifyContent='center'>
              <Button
                disabled={preAssemblyLimit > preAssemblyDocs.length}
                onClick={() => {
                  setPreAssemblyLimit(preAssemblyLimit + 30 > preAssemblyDocs.length ? preAssemblyDocs.length : preAssemblyLimit + 30)
                }}
              >
                Wczytaj więcej
              </Button>
            </Box>}
          </Box>
        </Grid>
        <Grid item xs={6} style={{borderLeft: '1px solid rgba(0,0,0,0.12)', background: '#d7d7d7'}}>
          <Box boxSizing='border-box' p={3} mb={5}>
            <h3 style={{marginTop: 0}}>Dokumentacja pomontażowa:</h3>
            {postAssemblyDocs.length === 0
              ? <p>Brak dokumentów</p>
              : <Grid container spacing={2} alignItems='center'>
                {postAssemblyDocs.map((doc, index) => {
                  return <TaskDocument
                    key={doc.id}
                    doc={doc}
                    setImagePreview={setImagePreview}
                    setShowImagePreview={setShowImagePreview}
                  />
                })}
              </Grid>
            }
            {postAssemblyDocs.length > 0 && <Box display='flex' justifyContent='center'>
              <Button
                disabled={postAssemblyLimit > postAssemblyDocs.length}
                onClick={() => {
                  setPostAssemblyLimit(postAssemblyLimit + 30 > postAssemblyDocs.length ? postAssemblyDocs.length : postAssemblyLimit + 30)
                }}
              >
                Wczytaj więcej
              </Button>
            </Box>}
          </Box>
        </Grid>
      </Grid>
      <Dialog maxWidth='xl' open={showImagePreview} onClose={() => {
        setShowImagePreview(false)
      }}>
        <Box display='flex' justifyContent='flex-end'>
          <IconButton size='large' onClick={() => {
            setShowImagePreview(false)
          }}>
            <Close />
          </IconButton>
        </Box>
        <DialogContent>
          {imagePreview &&
          <div style={{width: '100%', height: '80vh', position: 'relative', display: 'flex'}}>
            <Box style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <IconButton
                size='large'
                disabled={previewLoading}
                onKeyDown={e => {
                  if (e.keyCode === 37) {
                    handlePrevPreview()
                  } else if (e.keyCode === 39) {
                    handleNextPreview()
                  }
                }}
                onClick={() => handlePrevPreview()}
              >
                <ArrowBackIos />
              </IconButton>
            </Box>
            {
              imagePreview.docType.includes('image')
                ? <a onClick={e => e.preventDefault()} href={imagePreview?.url} download={imagePreview?.fileName}><img style={{height: '100%'}} alt='preview' src={imagePreview?.url}/>
                </a>
                : imagePreview.docType.includes('pdf')
                  ? <Grid item xs={12}>
                    <Box height='100%' display='flex' alignItems='center' flexDirection='column' justifyContent='center'>
                      <Box display='flex' justifyContent='center'>
                        <IconButton color='primary' onClick={() => {
                          var a = document.createElement("a"); //Create <a>
                          a.href = imagePreview.url; //Image Base64 Goes here
                          a.download = imagePreview.fileName; //File name Here
                          a.click(); //Downloaded file
                        }}>
                          <PictureAsPdf style={{fontSize: 80}} />
                        </IconButton>
                      </Box>
                      <p style={{textAlign: 'center', marginTop: '5px', wordBreak: 'break-all', cursor: 'pointer'}} onClick={() => {
                        var a = document.createElement("a"); //Create <a>
                        a.href = imagePreview.url; //Image Base64 Goes here
                        a.download = imagePreview.fileName; //File name Here
                        a.click(); //Downloaded file
                      }}>
                        {imagePreview.fileName}
                      </p>
                    </Box>
                  </Grid>
                  : <Grid item xs={12}>
                    <Box height='100%' display='flex' alignItems='center' flexDirection='column' justifyContent='center'>
                      <Box display='flex' justifyContent='center'>
                        <IconButton color='primary' onClick={() => {
                          var a = document.createElement("a"); //Create <a>
                          a.href = imagePreview.url; //Image Base64 Goes here
                          a.download = imagePreview.fileName; //File name Here
                          a.click(); //Downloaded file
                        }}>
                          <InsertDriveFile style={{fontSize: 80}} />
                        </IconButton>
                      </Box>
                      <p style={{textAlign: 'center', marginTop: '5px', wordBreak: 'break-all', cursor: 'pointer'}} onClick={() => {
                        var a = document.createElement("a"); //Create <a>
                        a.href = imagePreview.url; //Image Base64 Goes here
                        a.download = imagePreview.fileName; //File name Here
                        a.click(); //Downloaded file
                      }}>
                        {imagePreview.fileName}
                      </p>
                    </Box>
                  </Grid>

            }
            <Box style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'}}
            >
              <IconButton
                size='large'
                disabled={previewLoading}
                onKeyDown={e => {
                  if (e.keyCode === 37) {
                    handlePrevPreview()
                  } else if (e.keyCode === 39) {
                    handleNextPreview()
                  }}
                }
                onClick={() => handleNextPreview()}
              >
                <ArrowForwardIos/>
              </IconButton>
            </Box>
          </div>
          }
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default ProjectByLocationTile
