import {Box, Divider, Tooltip, Button, Link as LinkComp} from "@material-ui/core";
import {Link as RouterLink} from 'react-router-dom'
import {CheckBox, Close, Image} from "@material-ui/icons";
import {COLOR} from "../../theme";
import {useSelector} from "react-redux";
import {userSelector} from "../../features/User/UserSlice";
import {useHistory} from "react-router-dom";
import React from "react";
import {scrollToTopSmooth} from "../../helpers/utils";

const TaskTile = ({ task, projectId }) => {

  const { isPm, hasPermissionToVendorPanel } = useSelector(userSelector)
  const history = useHistory()

  return (
    <Box mt={1}>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Box>
          <LinkComp
            className='hover-underline'
            style={{cursor: 'pointer', color: 'black', fontSize: '20px', fontWeight: 'bold'}}
            href={isPm
              ? (`/tasks/${projectId}`)
              : hasPermissionToVendorPanel
                ? (`/supplier-tasks/${projectId}`)
                : (`/project/${projectId}`)}
          >
            {task.name}
          </LinkComp>
          {isPm && <p style={{mt: 0}}><b>Technik: </b>{task.supplier || '-'}</p>}
          <p style={{mt: 0, color: task?.note ? 'red' : 'inherit'}}><b>Notatka: </b>{task.note || '-'}</p>
          <p><b>Lokalizacja</b>: {`${task.location?.locationSymbol}${task.location.locationSymbol ? ' ' : ''}${task.location?.name}`}</p>
          <p>{task.location.city}, {task.location.street} {task.location.buildingNumber}</p>
        </Box>
        <Box display='flex'>
          <Box m={2}>
            <Tooltip title={task.documents.length === 0 ? 'Brak dodanych dokumentów' : `Liczba dokumentów: ${task.documents.length}`} placement='top'>
              <div style={{position: 'relative'}}>
                <Image style={{fontSize: 60, color: 'rgb(160, 208, 236)'}} />
                <div style={{position: 'absolute', bottom: 0, right: 0}}>
                  {
                    task.documents.length === 0
                      ? <div
                        style={{
                          height: '26px',
                          width: '26px',
                          background: 'red',
                          borderRadius: '50%',
                          color: 'white',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}>
                        <Close />
                      </div>
                      : <div
                        style={{
                          height: '26px',
                          width: '26px',
                          background: COLOR.primary,
                          borderRadius: '50%',
                          color: 'white',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}>
                        {task.documents.length}
                      </div>
                  }
                </div>
              </div>
            </Tooltip>
          </Box>
          {!(isPm || hasPermissionToVendorPanel) && <Box m={2}>
            <Tooltip title={task.status === 'Zrealizowane' ? 'Zadanie zrealizowane' : 'Zadanie do realizacji'}
                     placement='top'>
              <CheckBox
                style={{fontSize: 60, color: task.status === 'Zrealizowane' ? COLOR.success : COLOR.lightGrey}}/>
            </Tooltip>
          </Box>}
          <Box m={2} display='flex' alignItems='center'>
            <RouterLink style={{textDecoration: 'none'}} to={isPm
              ? (`/tasks/${projectId}/${task.id}`)
              : hasPermissionToVendorPanel
                ? (`/supplier-tasks/${projectId}/${task.id}`)
                : (`/project/${projectId}/${task.id}`)}>
              <Button
                fullWidth
                variant='contained'
                color='primary'
                style={{height: '24px'}}
              >
                Szczegóły
              </Button>
            </RouterLink>
          </Box>
        </Box>
      </Box>
      <Divider />

    </Box>
  )
}

export default TaskTile
